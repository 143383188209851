<template>
  <div class="privacy">
    <!-- 头部 -->
    <logged-heard></logged-heard>
    <!-- 内容区域 -->
    <div class="privacy-center space-between">
      <!-- 左侧tabbar区 -->
      <tabbar-model :typeId="1" :typeIndex="11" @toggleClick="toggleClick"></tabbar-model>
      <!-- 右侧内容区 -->
      <div class="right-privacy">
        <div class="row-center text-main font-bold right-center-title">
          <span>谁能查看我的简历</span>
          <img src="../../assets/forget/4.png" alt="" />
        </div>
        <div class="checkBox">
          <el-radio v-model="query.resume_status" :label="1" @change="changeRadio"
            >公开简历</el-radio
          >
          <div class="checkBox-text">帮我推荐好职位</div>
        </div>
        <div class="checkBox">
          <el-radio v-model="query.resume_status" :label="2" @change="changeRadio"
            >仅投递企业</el-radio
          >
          <div class="checkBox-text">只有我投递的企业可以查看我的简历</div>
        </div>
        <div class="checkBox">
          <el-radio v-model="query.resume_status" :label="3" @change="changeRadio"
            >隐藏简历</el-radio
          >
          <div class="checkBox-text">目前没有找工作，不希望企业可以查看我的简历</div>
        </div>
        <div class="text-main font-bold padding">屏蔽公司</div>
        <div class="text-main font-normal text-privacy padding">
          添加屏蔽公司后，你和这些公司的HR，都不会被相互推荐
        </div>
        <div class="padding">
          <div class="privacy-search row-center font-normal pointer" @click="searchClick">
            <img src="../../assets/forget/5.png" alt="" />
            <div>搜索公司全称，简称</div>
          </div>
        </div>
        <div
          class="shield-company space-between text-main font-lighter padding"
          v-if="companyShow == false"
        >
          <span>已经屏蔽{{ count }}家公司</span>
          <span class="text-blue font-normal pointer" @click="companyShow = true"
          v-if="shield_companyOne.length>0"
            >批量管理</span
          >
        </div>
        <div class="company-center font-normal text-main" v-if="companyShow == false">
          <div
            class="el-company-item-two pointer padding space-between"
            :class="mouserIndex == item.id ? 'companyItem' : ''"
            @mouseover="mouseOver(item.id)"
            @mouseleave="mouseLeave"
            v-for="item in shield_companyOne"
            :key="item.id"
          >
            <span>{{ item.ent_name }}</span>
            <div
              class="text-blue flex-center font-normal el-div"
              v-if="mouserIndex == item.id"
              @click="liftClick(item.id)"
            >
              解除屏蔽
            </div>
          </div>
          <div class="company-item company-item-one" v-if="shield_companyOne.length>0">
            {{ scrollText }}
          </div>
        </div>
        <!-- 选择状态 -->
        <div class="company-center font-normal padding text-main" v-else>
          <div class="space-between" style="margin-bottom: 0">
            <div>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选所有公司</el-checkbox
              >
            </div>
            <div class="row-center text-blue font-normal company-button">
              <div class="flex-center pointer el-div" @click="liftClickOne">
                解除屏蔽已选公司
              </div>
              <div class="flex-center pointer el-div el-div-two" @click="liftClickOne">
                清空所有屏蔽
              </div>
              <div
                class="bg-blue text-white flex-center pointer el-div el-div-three"
                @click="companyShow = false"
              >
                完成
              </div>
            </div>
          </div>
          <div style="margin: 15px 0"></div>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <div
              v-for="city in shield_companyOne"
              :key="city.id"
              class="company-item-one"
            >
              <el-checkbox :label="city.id">{{ city.ent_name }}</el-checkbox>
            </div>
          </el-checkbox-group>
          <div class="company-item">{{ scrollText }}</div>
        </div>
        <div class="switch-class padding">
          <div class="privacy-name row-center">
            <div class="text-main font-bold name-protect">真实姓名保护</div>
            <el-switch v-model="nameShow" @change="changeName"> </el-switch>
          </div>
          <div class="font-noraml">开启后，对外展示姓名时将是“某先生/某女士”</div>
        </div>
        <div class="switch-class padding">
          <div class="privacy-name row-center">
            <div class="text-main font-bold name-protect">手机号码保护</div>
            <el-switch v-model="phoneShow" @change="changePhone"> </el-switch>
          </div>
          <div class="font-noraml">开启后，企业将无法向您索要手机号码</div>
        </div>
      </div>
    </div>
    <!-- 搜索公司弹窗 -->
    <add-company ref="addMask" @buttonClick="buttonClick"></add-company>
    <!-- 底部 -->
    <feet-model></feet-model>
  </div>
</template>

<script>
import loggedHeard from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import tabbarModel from "../../components/tabbar.vue";
import addCompany from "./addCompany.vue";
import api from "../../api/user";
export default {
  components: { loggedHeard, feetModel, tabbarModel, addCompany },

  data() {
    return {
      nameShow: false,
      phoneShow: false,
      count: 0, //总共屏蔽了多少家公司
      countNum: 15, //当前处于第几条数据
      shield_company: [], //总共有多少条屏蔽公司的数据
      shield_companyOne: [], //当前展示的屏蔽公司数据
      scrollText: "滚动加载更多",
      scrollShow: true,
      // 判断是否处于管理状态
      companyShow: false,
      // 屏蔽公司操作
      checkAll: false,
      checkedCities: [],
      isIndeterminate: true,
      mouserIndex: "",
      query: {
        resume_status: 1, //简历状态设置 1 公开 2 仅投递企业 3 隐藏
        protect_name: 1, //姓名保护 0 未开启 1开启
        protect_phone: 0, //手机号码保护 0 未开启 1开启
        shield_company_ids: [],
      },
    };
  },

  created() {
    this.getPrivacy();
  },
  mounted() {
    // 监听滚动条的高度
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    toggleClick(){
      this.$emit('toggleClick');
    },
    // 屏蔽公司
    buttonClick(list) {
      this.query.shield_company_ids=this.query.shield_company_ids.concat(list);
      this.setPrivacy();
    },
    changePhone() {
      if (this.phoneShow) {
        this.query.protect_phone = 1;
      } else {
        this.query.protect_phone = 0;
      }
      this.setPrivacy();
    },
    changeName() {
      if (this.nameShow) {
        this.query.protect_name = 1;
      } else {
        this.query.protect_name = 0;
      }
      this.setPrivacy();
    },
    changeRadio() {
      this.setPrivacy();
    },
    // 设置隐私
    setPrivacy() {
      api.setPrivate(this.query).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.getPrivacy();
        }
      });
    },
    // 获取设置
    getPrivacy() {
      api.getPrivate().then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.query.resume_status = data.resume_status;
          this.query.protect_name = data.protect_name;
          this.query.protect_phone = data.protect_phone;
          this.shield_company = data.shield_company;
          this.shield_companyOne = data.shield_company.splice(0, 15);
          this.count = data.shield_company_num;
          this.query.shield_company_ids = data.shield_company_ids;
          if (data.protect_name == 1) {
            this.nameShow = true;
          }
          if (data.protect_phone == 1) {
            this.phoneShow = true;
          }
        }
      });
    },
    handleScroll() {
      if (this.countNum > this.count) {
        this.scrollText = "";
        return;
      }
      let data = this.shield_company;
      let scrollTop = document.documentElement.scrollTop; //滚动高度
      let clientHeight = document.documentElement.clientHeight; //可视高度
      let scrollHeight = document.documentElement.scrollHeight; //内容高度
      if (this.scrollShow == false) {
        return;
      }

      // 判断滚动条是否滚动到底部了
      if (scrollTop + clientHeight >= scrollHeight) {
        this.scrollShow = false;
        this.scrollText = "加载中...";
        setTimeout(() => {
          this.countNum = this.countNum + 15;
          this.shield_companyOne = data.splice(0, this.countNum);
          this.scrollShow = true;
        }, 1000);
      }
    },
    // 屏蔽公司操作
    handleCheckAllChange(val) {
      if (val) {
        this.checkedCities = this.shield_companyOne.map((item) => {
          return item.id;
        });
      } else {
        this.checkedCities = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.shield_companyOne.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.shield_companyOne.length;
    },
    // 清除已选的屏蔽公司
    liftClickOne() {
      if(this.checkedCities.length==0){
        this.$util.msg('请选择公司','warning')
        return
      }
      let data = [];
      for (let i = 0; i < this.query.shield_company_ids.length; i++) {
        if (!this.checkedCities.find((item) => item == this.query.shield_company_ids[i])) {
          data.push(this.query.shield_company_ids[i]);
        }
      }
      this.query.shield_company_ids=data
      this.setPrivacy()
    },
    // 搜索公司
    searchClick() {
      this.$refs.addMask.show();
    },
    // 鼠标移入事件
    mouseOver(index) {
      this.mouserIndex = index;
    },
    // 鼠标移出事件
    mouseLeave() {
      this.mouserIndex = "";
    },
    // 解除
    liftClick(id) {
      this.$confirm("是否清除所选屏蔽公司, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.query.shield_company_ids = this.query.shield_company_ids.filter((item) => {
            return item != id;
          });
          this.setPrivacy();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.privacy {
  width: 100%;
  background: #f6f6f8;
  min-height: 100vh;
  .padding {
    padding-left: 5.25rem;
  }
  .privacy-center {
    width: 100%;
    padding: 1.1875rem 11.875rem 2.25rem 11.875rem;
    align-items: flex-start;
    .right-privacy {
      background: #ffffff;
      box-shadow: 1px 0px 20px 1px #f6f6f8;
      flex: 1;
      margin-left: 1.25rem;
      padding: 2.125rem 0 1.875rem 0;
      .right-center-title {
        margin-bottom: 38px;
        padding-left: 5.25rem;
        img {
          width: 1.375rem;
          height: 1.375rem;
          margin-left: 0.625rem;
        }
      }
      .checkBox {
        margin-bottom: 2.5rem;
        padding-left: 5.25rem;

        .checkBox-text {
          color: #aeb1bb;
          font-size: 13px;
          padding-left: 1.375rem;
          margin-top: 0.6875rem;
        }
      }
      .checkBox:last-child {
        margin-bottom: 3.125rem;
      }
      .text-privacy {
        margin: 1.4375rem 0;
      }
      .privacy-search {
        width: 564px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e3e7ed;
        padding-left: 17px;
        color: #9fa3b0;
        margin-bottom: 2.3125rem;
        img {
          width: 13px;
          height: 12px;
          margin-right: 10px;
        }
      }
      .shield-company {
        margin-bottom: 1.5rem;
        padding-right: 5.0625rem;
      }
      .company-center {
        margin-bottom: 1.5rem;
        padding-right: 5.0625rem;
        .company-item-one {
          margin-bottom: 1.5rem;
        }
        .el-company-item-two {
          height: 40px;
          .el-div {
            width: 74px;
            height: 25px;
            border: 1px solid #126bf9;
          }
        }
        .companyItem {
          background: #f6f7fa;
        }
        .company-item {
          text-align: center;
        }
        .company-button {
          .el-div {
            width: 152px;
            height: 34px;
            border: 1px solid #126bf9;
            margin-left: 20px;
          }
          .el-div-three {
            width: 4.25rem;
            width: 68px;
            border: none;
          }
          .el-div-two {
            width: 125px;
          }
        }
      }
      .switch-class {
        color: #aeb1bb;
        margin-bottom: 3.125rem;
        .privacy-name {
          margin-bottom: 1.5rem;
          .name-protect {
            margin-right: 8.5625rem;
          }
        }
      }
      .switch-class:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
